<template>
  <table v-if="whichHalf === 'first'" class="firstTable">
    <tr>
      <td class="table__header" :colspan="seasonsArray().length + 5">
        {{ this.$route.params.clan }} Ranks 1 - 25
      </td>
    </tr>
    <tr>
      <th>Position</th>
      <th>Name</th>
      <th v-for="season in seasonsArray()" :key="season">
        Season {{ season }}
      </th>
      <th>Evolution</th>
      <th>Average</th>
      <th>Best score</th>
    </tr>
    <tr v-for="(playerId, index) in partialPlayerIds" :key="playerId">
      <td>{{ index + 1 }}</td>
      <td class="player__name">{{ findLastNameForPlayerId(playerId) }}</td>
      <td v-for="season in seasonsArray()" :key="season">
        {{ dataForSeason(season, playerId) }}
      </td>
      <td
          v-if="dataForSeason(lastSeasonAvailable - 1, playerId)"
          :style="{
          background: getColorScale(
            dataForSeason(lastSeasonAvailable, playerId),
            dataForSeason(lastSeasonAvailable - 1, playerId)
          ),
        }"
      >
        {{
          dataForSeason(lastSeasonAvailable, playerId) -
          dataForSeason(lastSeasonAvailable - 1, playerId)
        }}
      </td>
      <td v-else :style="{ background: getColorScale(0, 0) }">0</td>
      <td>{{ getSeasonsAverage(playerId) }}</td>
      <td>{{ getMaxScoreForPlayer(playerId) }}</td>
    </tr>
  </table>
  <table v-else-if="whichHalf === 'second'" class="secondTable">
    <tr>
      <td class="table__header" :colspan="seasonsArray().length + 5">
        {{ this.$route.params.clan }} Ranks 26 - 50
      </td>
    </tr>
    <tr>
      <th>Position</th>
      <th>Name</th>
      <th v-for="season in seasonsArray()" :key="season">
        Season {{ season }}
      </th>
      <th>Evolution</th>
      <th>Average</th>
      <th>Best score</th>
    </tr>
    <tr v-for="(playerId, index) in partialPlayerIds" :key="playerId">
      <td>{{ index + 26 }}</td>
      <td class="player__name">{{ findLastNameForPlayerId(playerId) }}</td>
      <td v-for="season in seasonsArray()" :key="season">
        {{ dataForSeason(season, playerId) }}
      </td>
      <td
          v-if="dataForSeason(lastSeasonAvailable - 1, playerId)"
          :style="{
          background: getColorScale(
            dataForSeason(lastSeasonAvailable, playerId),
            dataForSeason(lastSeasonAvailable - 1, playerId)
          ),
        }"
      >
        {{
          dataForSeason(lastSeasonAvailable, playerId) -
          dataForSeason(lastSeasonAvailable - 1, playerId)
        }}
      </td>
      <td v-else :style="{ background: getColorScale(0, 0) }">0</td>
      <td>{{ getSeasonsAverage(playerId) }}</td>
      <td>{{ getMaxScoreForPlayer(playerId) }}</td>
    </tr>
  </table>
</template>

<script>
import KILdata from '../KIL-MAIN-DB.json'

export default {
  data () {
    return {
      playersData: [],
      whichHalf: '',
    }
  },
  computed: {
    allPlayerIds () {
      let allPlayerIds = this.playersData.map((player) => player.Id)
      return [...new Set(allPlayerIds)]
    },
    partialPlayerIds () {
      if (this.whichHalf === 'first') {
        return this.playersSortedByScoreForSeason(this.lastSeasonAvailable)
            .slice(0, 25)
            .map((player) => player.Id)
      } else if (this.whichHalf === 'second') {
        return this.playersSortedByScoreForSeason(this.lastSeasonAvailable)
            .slice(25)
            .map((player) => player.Id)
      }
      return this.allPlayerIds
    },
    lastSeasonAvailable () {
      const lastSeasonWithAvailableData = Math.max(...this.seasonsArray())
      return this.dataForSeason(lastSeasonWithAvailableData)
          ? lastSeasonWithAvailableData
          : lastSeasonWithAvailableData - 1
    },
  },
  methods: {
    dataForSeason (season, playerId) {
      let trophyForSeason
      if (playerId && season) {
        // data for specific player for one season
        return this.playersData
            .filter((elem) => elem.Id === playerId)
            .filter((elem) => elem.Season === season)[0]?.ArenaTrophies
      } else if (playerId && !season) {
        // data for specific players for all seasons - used to calculate AVG
        trophyForSeason = this.playersData
            .filter((elem) => elem.Id === playerId)
            .map((elem) => elem.ArenaTrophies)
        return Math.round(
            trophyForSeason.reduce((a, b) => a + b, 0) /
            this.seasonsArray(playerId).length
        )
      } else {
        // all scores for last day of particular season
        trophyForSeason = this.playersData
            .filter((elem) => elem.Season === season)
            .filter((elem) => elem.Clan === this.$route.params.clan)
        return trophyForSeason.length ? trophyForSeason : 0
      }
    },
    seasonsArray (playerId) {
      // get seasons available for certain player
      if (playerId) {
        return [
          ...new Set(
              this.playersData
                  .filter((player) => player.Id === playerId)
                  .map((season) => season.Season)
          ),
        ]
      } else {
        // get all seasons for which there is a record
        const allSeasons = [
          // get unique values
          ...new Set(
              this.playersData
                  .map((season) => season.Season)
                  .sort()
          ),
        ]
        // show only last 5 seasons
        return allSeasons.length > 5
            ? allSeasons.slice(allSeasons.length - 5, allSeasons.length)
            : allSeasons
      }
    },
    playersSortedByScoreForSeason (season) {
      return this.dataForSeason(season).sort((a, b) =>
          a.ArenaTrophies > b.ArenaTrophies ? -1 : 1
      )
    },
    findLastNameForPlayerId (id) {
      return this.playersData.findLast((player) => player.Id === id)?.Name
    },
    getColorScale (lastSeasonScore, beforeLastSeasonScore) {
      if (lastSeasonScore + 200 < beforeLastSeasonScore) return 'salmon'
      else if (lastSeasonScore + 100 < beforeLastSeasonScore)
        return 'lightsalmon'
      else if (lastSeasonScore + 50 < beforeLastSeasonScore) return 'gold'
      else if (lastSeasonScore <= beforeLastSeasonScore) return 'khaki'
      else if (lastSeasonScore > beforeLastSeasonScore + 300)
        return 'mediumspringgreen'
      else return 'lightgreen'
    },
    getMaxScoreForPlayer (id) {
      return Math.max(
          ...this.playersData
              .filter((player) => player.Id === id)
              .map((player) => player.ArenaTrophies)
      )
    },
    getSeasonsAverage (id) {
      return this.dataForSeason(undefined, id)
    },
  },
  mounted () {
    this.playersData = KILdata
    if (
        (this.$route.params.whichHalf === 'first' ||
            this.$route.params.whichHalf === 'second') &&
        this.playersData
            .filter((elem) => elem.Season === this.lastSeasonAvailable)
            .filter((elem) => elem.Clan === this.$route.params.clan).length !== 0
    )
      this.whichHalf = this.$route.params.whichHalf
    else this.$router.push({ path: '/NotFound' })
  },
}
</script>

<style scoped>

table {
  vertical-align: middle;
  text-align: center;
  font-family: "Segoe UI Historic";
  width: 1200px;
  height: 900px;
}

th,
td {
  border: 1.5px solid black;
  padding: 2px 0;
}

th {
  background: floralwhite;
}

.player__name {
  background: honeydew;
}

.table__header {
  background: black;
  color: ghostwhite;
}
</style>
