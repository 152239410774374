<template>
  <table>
    <td class="table__header" colspan="3" >Movements</td>
    <tr>
    <th>Player</th>
      <th>Score</th>
    <th>Move to</th>
    </tr>
    <tr v-for="player in playersToBeMoved" :key="player.Id">
      <th class="player__name">{{player.name}}</th>
      <th>{{player.trophies}}</th>
      <th>{{player.clan}}</th>
    </tr>
  </table>
</template>

<script>
import KILdata from '../KIL-DAILY-DB.json'

export default {
  data () {
    return {
      movementData: [],
      playersToBeMoved: [],
      exceptionIdList: []
    }
  },
  methods: {
    orderedTable() {
      return this.movementData.sort((a,b) =>
      a.ArenaTrophies > b.ArenaTrophies ? -1 : 1).filter(record => !this.exceptionIdList.includes(record.Id))
      .map((record, index) => { return {position: index + 1, ...record} })
    },
    calculatePlayersToMove() {
      for (const player of this.orderedTable()) {
        if (player.position <= 50 && player.Clan !== "KIL") {
          this.playersToBeMoved.push({id: player.Id, name: player.Name, clan: "KIL", trophies: player.ArenaTrophies});
        }
        else if (player.position > 50 && player.position <= 100 && player.Clan !== "KIL2") {
          this.playersToBeMoved.push({id: player.Id, name: player.Name, clan: "KIL2", trophies: player.ArenaTrophies})
        }
        else if (player.position > 100 && player.Clan !== "KIL3") {
          this.playersToBeMoved.push({id: player.Id, name: player.Name, clan: "KIL3", trophies: player.ArenaTrophies})
        }
      }
    }
  },
  mounted () {
    this.movementData = KILdata;
    this.calculatePlayersToMove();
    if (this.movementData.length === 0) this.$router.push({ path: '/NotFound' })
  },
}
</script>

<style scoped>

table {
  vertical-align: middle;
  text-align: center;
  font-family: "Segoe UI Historic";
  font-size: 0.75rem;
  min-width: 600px;
  min-height: 800px;
}

th,
td {
  border: 1.5px solid black;
  padding: 2px 0;
}

th {
  background: floralwhite;
}

.player__name {
  background: honeydew;
}

.table__header {
  background: black;
  color: ghostwhite;
}
</style>