<template>
  <router-view> </router-view>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}
body {
  background: ghostwhite;
}
</style>
