<template>
  <table>
    <tr>
      <td class="table__header" :colspan="seasonsArray.length + 1">
        {{ playerName + " " + playerId }}
      </td>
    </tr>
    <tr>
      <th>Day</th>
      <th v-for="season in seasonsArray" :key="season">Season {{ season }}</th>
    </tr>
    <tr v-for="day in 14" :key="day">
      <th class="day">
        {{ day }}
      </th>
      <td
        :style="{
          background: this.getColorScale(
            this.maximumTrophiesEarnedByPlayer,
            this.minimumTrophiesEarnedByPlayer,
            this.dataForDay(season, day)
          ),
        }"
        v-for="season in seasonsArray"
        :key="season"
      >
        {{ this.dataForDay(season, day) }}
      </td>
    </tr>
  </table>
</template>

<script>
import KIL1data from "../KIL-MAIN-DB.json";

export default {
  data() {
    return {
      allPlayersData: [],
      individualPlayerData: [],
      playerId: "",
      playerName: "",
    };
  },
  computed: {
    seasonsArray() {
      const allSeasons = [
        // get unique values
        ...new Set(
          this.individualPlayerData.map((season) => season.Season).sort()
        ),
      ];
      // show only last 5 seasons
      return allSeasons.length > 5
        ? allSeasons.slice(allSeasons.length - 5, allSeasons.length)
        : allSeasons;
    },
    maximumTrophiesEarnedByPlayer() {
      return Math.max(
        ...this.individualPlayerData.map((player) => player.ArenaTrophies)
      );
    },
    minimumTrophiesEarnedByPlayer() {
      return Math.min(
        ...this.individualPlayerData.map((player) => player.ArenaTrophies)
      );
    },
  },
  methods: {
    findIdForPlayerName(name) {
      return this.allPlayersData.findLast(
        (player) => player.Name.toLowerCase() === name.toLowerCase()
      )?.Id;
    },
    findLastNameForPlayerId(id) {
      return this.allPlayersData.findLast((player) => player.Id === id)?.Name;
    },
    dataForDay(season, day) {
      const trophyForDay = this.individualPlayerData
        .filter((element) => element.Season === season)
        .filter((elem) => elem.Day === day)[0]?.ArenaTrophies;
      return trophyForDay ? trophyForDay : "0";
    },
    getColorScale(
      maximumTrophiesEarnedByPlayer,
      minimumTrophiesEarnedByPlayer,
      currentTrophyToEstimate
    ) {
      //TODO: Maybe make it so that it relates to the maximum score on that day and not the maximum score overall..
      const difference =
        (maximumTrophiesEarnedByPlayer - minimumTrophiesEarnedByPlayer) / 5;
      if (currentTrophyToEstimate == 0) return "salmon";
      else if (
        currentTrophyToEstimate <
        minimumTrophiesEarnedByPlayer + 1.2 * difference
      )
        return "lightsalmon";
      else if (
        currentTrophyToEstimate <
        minimumTrophiesEarnedByPlayer + 2.4 * difference
      )
        return "khaki";
      else if (
        currentTrophyToEstimate <
        minimumTrophiesEarnedByPlayer + 3.6 * difference
      )
        return "gold";
      else if (
        currentTrophyToEstimate <
        minimumTrophiesEarnedByPlayer + 4.8 * difference
      )
        return "lightgreen";
      else return "mediumspringgreen";
    },
    containsOnlyNumbers(str) {
      return /^\d+$/.test(str);
    },
  },
  mounted() {
    this.allPlayersData = KIL1data;
    if (this.containsOnlyNumbers(this.$route.params.id)) {
      //receives ID
      this.playerId = this.$route.params.id;
      this.playerName = this.findLastNameForPlayerId(this.$route.params.id);
    } else {
      //receives NAME
      this.playerId = this.findIdForPlayerName(this.$route.params.id);
      this.playerName = this.findLastNameForPlayerId(this.playerId);
    }

    if (
      !this.playerName ||
      !this.playerId ||
      this.allPlayersData.length === 0
    ) {
      this.$router.push({ path: "/NotFound" });
    }
    //search player by ID (names are ever-changing)
    else
      this.individualPlayerData = this.allPlayersData.filter(
        (player) => player.Id === this.playerId
      );
  },
};
</script>

<style scoped>
.table_styling {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  vertical-align: middle;
  text-align: center;
  font-family: "Segoe UI Historic";
}

table {
  vertical-align: middle;
  text-align: center;
  font-family: "Segoe UI Historic";
  width: 800px;
  height: 700px;
}

th,
td {
  padding: 7.5px 10px;
  border: 1.5px solid black;
}

th {
  background: floralwhite;
}

.table__header {
  background: black;
  color: ghostwhite;
}
</style>
