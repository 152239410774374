<template>
  <table>
    <tr>
      <td class="table__header" :colspan="powerArray.length + 1">
        {{ this.$route.params.clan }} Hero Table
      </td>
    </tr>
    <td>Hero</td>

    <td v-for="power in powerArray" :key="power">
      {{ power }}
    </td>

    <tr v-for="hero in heroArray" :key="hero">
      <td class="hero__name">{{ hero }}</td>
      <td v-for="power in powerValueArray" :key="power">
        {{ getPlayersForHeroAndValue(hero, power) }}
      </td>
    </tr>
  </table>
</template>

<script>
import KILdata from '../KIL-HERO-DB.json'

export default {
  data () {
    return {
      heroData: [],
      heroArray: [
        'Aly',
        'Davison',
        'Selene',
        'Zeus',
        'Seondeok',
        'Harrison',
        'Arthur',
        'Chione',
        'Jinn',
      ],
      powerArray: ['1.5M +', '1.6M +', '1.7M +', '1.8M +', '1.9M +', '2M +'],
      powerValueArray: [1500000, 1600000, 1700000, 1800000, 1900000, 2000000],
    }
  },
  methods: {
    getPlayersForHeroAndValue (hero, power) {
      return this.heroData
          .filter(
              (player) =>
                  player.Hero === hero &&
                  player.Combat - power <= 99999 &&
                  player.Combat - power > 0
          )
          .map((player) => player.Name)
          .join(', ')
    },
  },
  mounted () {
    this.heroData = KILdata.filter(
        (player) => player.Clan === this.$route.params.clan
    )

    if (this.heroData.length === 0) this.$router.push({ path: '/NotFound' })
  },
}
</script>

<style scoped>

table {
  vertical-align: middle;
  text-align: center;
  font-family: "Segoe UI Historic";
  width: 800px;
  height: 600px;
}

th,
td {
  border: 1.25px solid black;
  padding: 2px 0;
  min-width: 100px;
  font-size: 12px;
}

th {
  background: floralwhite;
}

.hero__name {
  background: honeydew;
}

.table__header {
  background: black;
  color: ghostwhite;
}
</style>
