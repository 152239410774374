import { createApp } from "vue";
import App from "./App.vue";
import NotFound from "./components/NotFound";
import IndividualProgression from "./components/IndividualProgression";
import TeamProgression from "./components/TeamProgression";
import HeroUsage from "./components/HeroUsage";
import { createRouter, createWebHistory } from "vue-router";
import ProgressionMovements from '@/components/ProgressionMovements'

const routes = [
  {
    path: "/:id",
    name: "KIL individual progressions",
    component: IndividualProgression,
  },
  {
    path: "/:clan/:whichHalf",
    name: "KIL team progressions",
    component: TeamProgression,
  },
  { path: "/:clan/heroUsage", name: "KIL hero usage", component: HeroUsage },
  { path: "/movements", name: "KIL movements", component: ProgressionMovements},
  { path: "/notFound", name: "NotFound", component: NotFound },
  { path: "/:notFound(.*)", redirect: "/NotFound" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);
app.use(router);
app.mount("#app");
